@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-xl;
  }
  h4 {
    @apply text-xl;
  }
  h5 {
    @apply text-xl;
    @apply font-semibold;
  }
  h6 {
    @apply font-semibold;
  }
}